import jsonArrayToXLSX from '~/utils/jsonArrayToXLSX'
import { objectToXlsxInGroups } from '~/utils/downloadPricesXLSX'

const downloadXLSX = (name: any, xlsx: any) => {
  const url = URL.createObjectURL(xlsx)
  const link = document.createElement('a')
  link.href = url
  link.download = `${name}.xlsx`
  document.body.appendChild(link)
  link.click()
}

export const getFileNameByProduct = ({ name, view, customDataTestProp, chartId, product }) => {
  const formattedName = name ? name + '-' : ''
  const formattedView = view ? view + '-' : ''
  const formattedCustomDataTestProp = customDataTestProp || chartId
  return `${product}-${formattedName}${formattedView}${formattedCustomDataTestProp}`
    .replaceAll(/,\s+/g, '_')
    .replaceAll(/\s+/g, '_')
    .replace(/[()]/g, '')
    .toLowerCase()
}

export const getTitleByProduct = ({ name, view, chartId, product }) => {
  const formattedName = name ? name + ': ' : ''
  const formattedView = view ? view + ': ' : ''
  return `${product}: ${formattedName}${formattedView}${chartId}`
    .replaceAll('-', ' ')
    .replaceAll('_', ' ')
}

const getXlsxBlob = ({ csvPayload, orderOfHeaders }) => {
  if (csvPayload.pricesData) return objectToXlsxInGroups(csvPayload.pricesData)
  return jsonArrayToXLSX(csvPayload, orderOfHeaders)
}

interface DownloadXlsParams {
  fileName: string
  csvPayload: object
  orderOfHeaders?: any[]
}

export const downloadXLSXFromPayload = ({
  fileName,
  csvPayload,
  orderOfHeaders = [],
}: DownloadXlsParams) => {
  const formattedFileName = fileName
  const xlsxBlob = getXlsxBlob({ csvPayload, orderOfHeaders })
  downloadXLSX(formattedFileName, xlsxBlob)
}
