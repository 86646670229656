<template>
  <div class="flex flex-row items-center gap-2" data-testid="container-chart-range-filter">
    <div class="flex flex-col">
      <span class="text-white text-xs font-semibold">Date Range</span>
      <div class="flex flex-row gap-3">
        <button
          v-for="mode in modeChartRangeFilter"
          :id="mode.id"
          :key="mode.value"
          type="button"
          class="font-semibold text-xs"
          :data-testid="`button-${mode.value}-chart-range-filter`"
          :class="[
            activeMode === mode.value ? 'text-p-yellow-500' : 'text-white/60',
            cursorClass(mode),
          ]"
          :disabled="activeMode === mode.value"
          @click="mode.onClick"
        >
          {{ mode.name }}
        </button>
      </div>
    </div>
    <div class="flex flex-row gap-2 relative">
      <YearPicker
        id="start-year"
        ref="startYearPicker"
        data-test-id="start-year"
        :exclude-elements="['customTime']"
        :value="selectedStartYear"
        :years="startYears"
        :highlight-border="activeMode === ChartRangeFilterMode.CUSTOM"
        @change-value="onChangeStartYear"
        @invalid-date="onInvalidStartYear"
      />
      <YearPicker
        id="end-year"
        data-test-id="end-year"
        :value="selectedEndYear"
        :years="endYears"
        :highlight-border="activeMode === ChartRangeFilterMode.CUSTOM"
        @change-value="onChangeEndYear"
        @invalid-date="onInvalidEndYear"
      />
      <YearPickerError
        v-if="isInvalidDate"
        :end-year="endYear"
        :start-year="startYear"
        :last-updated-value="lastUpdatedValue"
        :show-invalid-date="isInvalidDate"
        @close-invalid-date="closeInvalidDate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import YearPicker from '~/components/ui/YearPicker.vue'
import { ChartRangeFilterMode } from '~/constants/lithium/consts'

const props = defineProps<{
  startYear: number
  endYear: number
  defaultStartYear?: number
  defaultEndYear?: number
}>()

const selectedStartYear = computed({
  get() {
    return props.defaultStartYear || props.startYear
  },
  set(year) {
    emit('changeStartYear', year)
  },
})
const selectedEndYear = computed({
  get() {
    return props.defaultEndYear || props.endYear
  },
  set(year) {
    emit('changeEndYear', year)
  },
})
const startYearPicker = ref()
const lastUpdatedValue = ref()

const startYears = computed(() => {
  return generateYears(props.startYear, selectedEndYear.value)
})

const endYears = computed(() => {
  return generateYears(selectedStartYear.value, props.endYear)
})

const closeInvalidDate = () => {
  isInvalidDate.value = false
}

const isInvalidDate = ref(false)

const emit = defineEmits(['changeStartYear', 'changeEndYear'])

const generateYears = (startYear: number, endYear: number) => {
  const years: number[] = []
  for (let i = startYear; i <= endYear; i++) {
    years.push(i)
  }
  return years
}

const onChangeStartYear = (year: number) => {
  selectedStartYear.value = year
}

const onChangeEndYear = (year: number) => {
  selectedEndYear.value = year
}

const onInvalidStartYear = () => {
  onChangeStartYear(props.startYear)
  lastUpdatedValue.value = props.startYear
  isInvalidDate.value = true
}

const onInvalidEndYear = () => {
  onChangeEndYear(props.endYear)
  lastUpdatedValue.value = props.endYear
  isInvalidDate.value = true
}

const activeMode = computed(() => {
  if (selectedStartYear.value === props.startYear && selectedEndYear.value === props.endYear) {
    return ChartRangeFilterMode.ALL_TIME
  }

  return ChartRangeFilterMode.CUSTOM
})

const cursorClass = (item) => {
  if (!item.onClick) {
    return 'cursor-default'
  }

  return activeMode.value !== item.value ? 'cursor-pointer' : 'cursor-not-allowed'
}

const setAllTimeMode = () => {
  selectedStartYear.value = props.startYear
  selectedEndYear.value = props.endYear
  emit('changeStartYear', props.startYear)
  emit('changeEndYear', props.endYear)
}

const handleClickCustom = () => {
  startYearPicker.value.focusInput()
}

const modeChartRangeFilter = ref([
  {
    name: 'All time',
    value: ChartRangeFilterMode.ALL_TIME,
    onClick: setAllTimeMode,
    id: 'allTime',
  },
  {
    name: 'Custom',
    value: ChartRangeFilterMode.CUSTOM,
    onClick: handleClickCustom,
    id: 'customTime',
  },
])
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
</style>
