import { utils, write } from '@sheet/core'
import { DateTime } from 'luxon'
import { format, parseISO } from 'date-fns'

import { getPropertyOrCategoryByProperty } from '~/constants/general'

const fontStyleDefault = Object.freeze({ name: 'Arial', sz: 10 })

const cellStylesNormal = {
  alignment: { wrapText: false },
}

/**
 * Generate a cell object of type date for SheetJS.
 */
const cellObjectDate = (value: string | Date, options = {}, format = 'yyyy-MM-dd') => {
  const date =
    typeof value === 'string' ? DateTime.fromFormat(value, format) : DateTime.fromJSDate(value)
  return {
    f: `DATE(${date.year}, ${date.month}, ${date.day})`,
    z: 'dd mmm yyyy',
    s: {
      ...fontStyleDefault,
      ...options,
    },
  }
}

/**
 * Generate a cell object of type number for SheetJS.
 */
const cellObjectNumber = (value: number, options = {}, format = '#,##0') => ({
  t: 'n',
  v: value,
  z: format,
  s: {
    ...fontStyleDefault,
    ...options,
  },
})

/**
 * Generate a cell object of type text
 */
const cellObjectText = (value: string, options = {}, bold = false) => ({
  t: 's',
  v: value,
  s: {
    bold,
    ...fontStyleDefault,
    ...options,
  },
})

/**
 * Generates an array of objects with the price data of a
 * "Price Grade" with the structure expected by Sheetjs
 * @param {Series[]} series
 * @return {array}
 */
const generatePriceRows = (series) => {
  let lastRows = 11
  let rangeMonth = 0
  let monthAverageCellObject
  return series.map((item, index) => {
    const isLastItem = index + 1 === series.length
    const nextItem = isLastItem ? item : series[index + 1]
    const nextDate = nextItem.date.substring(0, 7)
    const actualDate = item.date.substring(0, 7)
    const isMonthAverage = isLastItem || nextDate !== actualDate

    if (isMonthAverage) {
      monthAverageCellObject = {
        f: `AVERAGE(D${lastRows}:D${lastRows + rangeMonth})`,
        z: '#,##0',
        s: {
          ...fontStyleDefault,
        },
      }
      lastRows = 11 + index + 1
      rangeMonth = 0
    } else {
      rangeMonth += 1
      monthAverageCellObject = undefined
    }
    return {
      A: cellObjectDate(item.date),
      B: cellObjectNumber(item.valueLow),
      C: cellObjectNumber(item.valueHigh),
      D: cellObjectNumber(item.valueMid),
      E: monthAverageCellObject,
    }
  })
}

const extractHeaderProperties = (group) => {
  const groupKey = group.category.name
  const shippingRouteName = group.shippingRoute ? group.shippingRoute.name : group.priceType.name

  const purity = group.purity.name
  const categoryName = groupKey
  const formula = getPropertyOrCategoryByProperty(groupKey, 'name', 'alias')

  return { groupKey, shippingRouteName, categoryName, formula, purity }
}

const purityTextGenerator = (formula, purity) => {
  return formula.concat(' ').concat(purity)
}

/**
 * Generates the text for the Price Grade field
 * @param {string} category - Category.
 * @param {PriceSeries} group - Price Grade.
 * @return {string}
 */
const priceGradeGenerator = (group) => {
  const { purity, shippingRouteName, categoryName, formula } = extractHeaderProperties(group)

  return `Benchmark ${categoryName}, ${shippingRouteName}, ${formula} ${purity}`
}

const headersGenerator = (group) => {
  const { purity, shippingRouteName, groupKey, formula } = extractHeaderProperties(group)
  const copySeries = [...group.series]
  const firstDate = copySeries.shift().date
  const lastDate = copySeries.length === 0 ? firstDate : copySeries.pop().date
  return [
    {
      A: cellObjectText('Start Date', true),
      B: cellObjectDate(firstDate),
    },
    {
      A: cellObjectText('End Date', true),
      B: cellObjectDate(lastDate),
    },
    {
      A: cellObjectText('Currency', true),
      B: cellObjectText(group.currency?.iso),
    },
    {},
    {
      A: cellObjectText('Price Grade', true),
      B: {
        t: 's',
        v: priceGradeGenerator(group),
        s: {
          ...fontStyleDefault,
          font: { vertAlign: true },
          alignment: { vertical: true, horizontal: true, wrapText: true },
        },
      },
    },
    {
      A: cellObjectText('Shipping term', true),
      B: cellObjectText(shippingRouteName),
    },
    {
      A: cellObjectText('Purity', true),
      B: cellObjectText(purityTextGenerator(formula, purity)),
    },
    {
      A: cellObjectText('Data source', true),
      B: cellObjectText('Benchmark Mineral Intelligence'),
    },
    {
      A: cellObjectText('Unit', true),
      B: cellObjectText(getPropertyOrCategoryByProperty(groupKey, 'name', 'unit')),
    },
    {
      A: cellObjectText('Date', true),
      B: cellObjectText('Low', true),
      C: cellObjectText('High', true),
      D: cellObjectText('Mid', true),
      E: cellObjectText('Month Average', true),
    },
  ]
}

const createNewSheet = (jsonData) =>
  utils.json_to_sheet(jsonData, {
    skipHeader: true,
  })

const createNameSheet = (serie) => {
  const { priceType } = serie
  const { categoryName, shippingRouteName } = extractHeaderProperties(serie)

  const property = getPropertyOrCategoryByProperty(categoryName, 'name', 'alias')
  const gradeName = serie.grade ? ` (${serie.grade})` : ''
  let sheetName = ''
  if (priceType.name === 'Daily Price Indicator') {
    sheetName = `${property} Daily Price Indicator`
  } else {
    sheetName = `${property} ${shippingRouteName}${gradeName}`
  }

  return sheetName.replaceAll('/', '').substring(0, 31)
}

const getValueByPath = (obj: any, path: string) => {
  const keys = path.split('.')
  let value = obj

  if (obj[keys[0]] === undefined || obj[keys[0]] === null) return obj[keys[0]]

  for (const key of keys) {
    if (value[key] === undefined) {
      break
    }
    value = value[key]
  }

  return value
}

const sortDataByCategory = (series: any[]) => {
  const keys = ['category.name', 'shippingRoute.name']
  return series.sort((a, b) => {
    for (const key of keys) {
      const aKey = getValueByPath(a, key)
      const bKey = getValueByPath(b, key)

      if (aKey < bKey) return -1
      if (aKey > bKey) return 1
    }

    return 0
  })
}

type SummaryRowType = {
  date: string
  meshSize: string
  mineral: string
  priceLow: number
  priceHigh: number
  priceMid: number
  payableLow: number
  payableHigh: number
  payableMid: number
  purity: string
  shippingRegion: string
  type: string
}

const getValuesForSummary = (rawData) => {
  const data = {}

  rawData.forEach((item) => {
    const idSeries = `${item.shippingRoute?.name}-${item.purity?.name}-${item.category?.name}`
    data[idSeries] = []
    item.series.forEach((serie) => {
      data[idSeries].push({
        date: serie.date ? format(parseISO(serie.date), 'yyyy-MM-dd') : '',
        meshSize: serie?.size || '',
        mineral: item.mineral || '',
        priceLow: serie.valueLow || '',
        priceHigh: serie.valueHigh || '',
        priceMid: serie.valueMid || '',
        payableLow: serie.payableLow || '',
        payableHigh: serie.payableHigh || '',
        payableMid: serie.payableMid || '',
        purity: item.purity.name || '',
        shippingRegion: item.shippingRoute?.name || '',
        type: item.category?.name || '',
        currency: item.currency?.iso || '',
      })
    })
  })

  return data
}

const sortDate = (a: SummaryRowType, b: SummaryRowType) =>
  new Date(a.date).getTime() - new Date(b.date).getTime()

const addSummary = (workbook, rawData, category) => {
  const values = getValuesForSummary(rawData)
  const format = getPropertyOrCategoryByProperty(category, 'category', 'format')
  const rows = []

  Object.keys(values).forEach((key) => {
    values[key]?.sort(sortDate)?.forEach((_item: SummaryRowType) => {
      rows.push([
        cellObjectDate(_item.date, cellStylesNormal),
        cellObjectText(_item.meshSize, cellStylesNormal),
        cellObjectText(_item.mineral, cellStylesNormal),
        cellObjectNumber(_item.priceLow, format),
        cellObjectNumber(_item.priceHigh, format),
        cellObjectNumber(_item.priceMid, format),
        cellObjectNumber(_item.payableLow, format),
        cellObjectNumber(_item.payableHigh, format),
        cellObjectNumber(_item.payableMid, format),
        cellObjectText(_item.purity, cellStylesNormal),
        cellObjectText(_item.shippingRegion, cellStylesNormal),
        cellObjectText(_item.type, cellStylesNormal),
        cellObjectText(_item.currency, cellStylesNormal),
      ])
    })
  })

  const summarySheet = createNewSheet([
    [
      cellObjectText('Date', cellStylesNormal, true),
      cellObjectText('MeshSize', cellStylesNormal, true),
      cellObjectText('Mineral', cellStylesNormal, true),
      cellObjectText('PriceLow', cellStylesNormal, true),
      cellObjectText('PriceHigh', cellStylesNormal, true),
      cellObjectText('PriceMid', cellStylesNormal, true),
      cellObjectText('PayableLow', cellStylesNormal, true),
      cellObjectText('PayableHigh', cellStylesNormal, true),
      cellObjectText('PayableMid', cellStylesNormal, true),
      cellObjectText('Purity', cellStylesNormal, true),
      cellObjectText('ShippingRegion', cellStylesNormal, true),
      cellObjectText('Type', cellStylesNormal, true),
      cellObjectText('Currency', cellStylesNormal, true),
    ],
    ...rows,
  ])

  /* Change the height of the rows */
  summarySheet['!rows'] = new Array(rows.length + 1).fill(null).map(() => ({ hpt: 22 }))

  /* Change the width of the columns */
  summarySheet['!cols'] = [
    { wpx: 80 },
    { wpx: 100 },
    { wpx: 80 },
    { wpx: 80 },
    { wpx: 80 },
    { wpx: 80 },
    { wpx: 80 },
    { wpx: 80 },
    { wpx: 90 },
    { wpx: 150 },
    { wpx: 150 },
  ]

  utils.book_append_sheet(workbook, summarySheet, 'Summary')
  return workbook
}

const createSheetsForGroups = (pricesData, category, rawData) => {
  const workbook = utils.book_new()
  const sheetNames = new Set()

  addSummary(workbook, rawData, category)

  pricesData.forEach((priceItem) => {
    /* Generates the objects with the information from rows 1 to 9 */
    const xlsxJSONData = headersGenerator(priceItem)
    /* Generates the objects with the price information */
    xlsxJSONData.push(...generatePriceRows(priceItem.series))
    /* Generate sheet names dynamically */
    const sheetName = createNameSheet(priceItem)
    let sheetNameFinal = sheetName?.toLowerCase()?.substring(0, 30)

    if (sheetNames.has(sheetNameFinal)) {
      sheetNameFinal = sheetNameFinal.substring(0, 29).concat('_')
    }
    sheetNames.add(sheetNameFinal)
    /* Transforms the created object into an Excel sheet */
    const newSheet = createNewSheet(xlsxJSONData)

    /* Change the height of the rows */
    newSheet['!rows'] = new Array(xlsxJSONData.length)
      .fill(null)
      .map((_, index) => ({ hpt: index === 4 ? 70 : undefined }))
    /* Change the width of the columns */
    newSheet['!cols'] = [{ wpx: 80 }, { wpx: 100 }, { wpx: 60 }, { wpx: 60 }, { wpx: 60 }]
    /* Add the created sheet to the Excel workbook */
    utils.book_append_sheet(workbook, newSheet, sheetNameFinal)
  })
  return workbook
}

const createBlobToWorkbook = (workbook) => {
  return new Blob([write(workbook, { type: 'array', bookType: 'xlsx', cellStyles: true })])
}

export const objectToXlsxInGroups = (rawData) => {
  const sortedData = sortDataByCategory(rawData)
  const workbook = createSheetsForGroups(sortedData, 'lithium', rawData)
  return createBlobToWorkbook(workbook)
}
