import domtoimage from 'dom-to-image'
import { useNuxtApp } from 'nuxt/app'
import { getCurrentInstance } from 'vue'
import { useCathodeStore } from '~/stores/cathode'

type OptionDownloadChartImage = {
  id: string
  elementId: string
  formattedName: string
}

interface DownloadChartImage {
  onDownloadChartImage: ({ chartId, formattedName, formattedTitle }) => void
  onDownloadChartImageV2: ({
    elementId,
    formattedName,
    id,
  }: OptionDownloadChartImage) => Promise<void>
}

export const BMLogo =
  'https://res.cloudinary.com/benchmarkminerals/image/upload/c_fill,q_auto,f_auto,w_150/v1706722768/pro-assets/terminal-logo-black_jb2w6g.png'
export const WaterMarkLogo =
  'https://res.cloudinary.com/benchmarkminerals/image/upload/c_fill,q_auto,f_auto,w_400/v1686334298/pro-assets/logo-benchmark_sfu0gz.svg'

const useDownloadChartImage = (): DownloadChartImage => {
  const vueInstance: any = getCurrentInstance()
  const app = useNuxtApp()

  const cathodeStore = useCathodeStore()
  const {
    setScaleTextColor,
    setScaleFontSizeY,
    setScaleFontSizeX,
    setScaleTitleFontSize,
    setSelectedChartIdDownloadToImage,
  } = cathodeStore

  const getChartWidth = (chartCanvas, legend, statusFilters) => {
    let chartWidth = chartCanvas.width + 50
    if (legend || statusFilters) chartWidth = chartCanvas.width + 360
    return chartWidth
  }

  const printCanvasHeight = (chartCanvas, legend, pricesToggles) => {
    let additionalHeight = 120
    if (legend && legend.offsetHeight > chartCanvas.height + 30) {
      additionalHeight = legend.offsetHeight - chartCanvas.height + 30
    }
    if (pricesToggles) additionalHeight = pricesToggles.offsetHeight + 210
    return chartCanvas.height + additionalHeight
  }

  const printCanvasWidth = (titleWidth, chartWidth) => {
    return titleWidth > chartWidth ? titleWidth : chartWidth
  }

  const toggleTooltipAndDropdown = (activate, chartId) => {
    const chart = document.getElementById(chartId)
    if (chart && !activate) chart.style.pointerEvents = 'none' /* Disable Tooltip */
    if (chart && activate) chart.style.pointerEvents = 'auto' /* Enable Tooltip */
  }

  const checkChartCanvas = (chartCanvas) => {
    if (!chartCanvas?.toDataURL) {
      throw new Error('chart canvas missing')
    }
  }

  const checkSelector = (selector, chartId) => {
    const wrapper = document.querySelector(`#${chartId}-wrapper`)
    return wrapper ? wrapper.querySelector(selector) : null
  }

  const handleSeveralCharts = (ref, context) => {
    const severalCharts = ref.querySelectorAll('canvas')
    // several charts in one  image
    if (severalCharts && severalCharts.length > 6) {
      context.drawImage(severalCharts[1], severalCharts[0].width + 100, 100)
      context.drawImage(severalCharts[3], severalCharts[0].width + 100, 200)
      context.drawImage(severalCharts[5], severalCharts[0].width + 100, 300)
    } else if (severalCharts && severalCharts.length > 4) {
      context.drawImage(severalCharts[2], severalCharts[2].width + 10, 100)
      context.drawImage(severalCharts[4], severalCharts[4].width * 2, 100)
    }
    // }
  }

  const watermarkVerticalPosition = (printHeight, pricesToggles, size) => {
    return pricesToggles ? 360 : printHeight / 2 - size / 2
  }

  const loadImageAsync = (src) => {
    const image = new Image()
    image.src = src
    image.crossOrigin = 'anonymous'
    return new Promise((resolve) => {
      image.onload = () => resolve(image)
    })
  }

  const getContextById = async (chartId) => {
    const ref = vueInstance.refs[chartId]

    if (!ref) {
      throw new Error('chart ref not assigned')
    }
    let canvas = ref.querySelector('canvas')

    const divToDownload = checkSelector('div.div-to-download', chartId)
    if (divToDownload) {
      const newCanvas = document.createElement('canvas')
      const divWidth = divToDownload.offsetWidth
      const divHeight = divToDownload.offsetHeight
      newCanvas.width = divWidth
      newCanvas.height = divHeight

      const chartDataURL = await domtoimage.toPng(divToDownload)
      const chartImage = await loadImageAsync(chartDataURL)

      const contextNewCanvas = newCanvas.getContext('2d')
      contextNewCanvas.drawImage(chartImage, 0, 0, divWidth, divHeight)

      canvas = newCanvas
    }

    checkChartCanvas(canvas)

    return { ref, canvas }
  }

  const onDownloadChartImage = ({ chartId, formattedName, formattedTitle }) => {
    toggleTooltipAndDropdown(0, chartId)
    setSelectedChartIdDownloadToImage(chartId)
    setScaleTextColor('#000000')

    try {
      setTimeout(async () => {
        const { ref, canvas } = await getContextById(chartId)
        const title = formattedTitle

        const printCanvas = document.createElement('canvas')
        const context = printCanvas.getContext('2d')
        const fixedLabels = document.querySelector('.fixedLabels')
        const statusFilters = checkSelector('.status-filters--wrapper', chartId)
        const legend = checkSelector('div#container-toggles', chartId)
        const pricesToggles = checkSelector('div#prices-toggles', chartId)
        const chartWidth = getChartWidth(canvas, legend, statusFilters)

        /* If the title does not fit we make the width of image = width of the title + 100 (padding). */
        const titleWidth = title.length * 9
        printCanvas.width = printCanvasWidth(titleWidth, chartWidth)
        printCanvas.height = printCanvasHeight(canvas, legend, pricesToggles)

        printCanvas.fillStyle = 'black'
        context.drawImage(canvas, 20, 100)

        context.fillStyle = 'black'
        context.font = '14px Lato, sans-serif'
        context.textAlign = 'center'
        context.fillText(title.toUpperCase(), printCanvas.width / 2, 30)

        const wmWidth = 450
        const wmHeight = wmWidth * 0.15
        const watermark = await loadImageAsync(WaterMarkLogo)
        context.globalAlpha = 0.2
        const wmHorPos = printCanvas.width / 2 - wmWidth / 2
        const wmVertPos = watermarkVerticalPosition(printCanvas.height, pricesToggles, wmHeight)
        context.drawImage(watermark, wmHorPos, wmVertPos, wmWidth, wmHeight)
        context.globalAlpha = 1.0

        context.fillStyle = 'black'
        context.font = '14px Lato, sans-serif'
        context.textAlign = 'center'
        context.fillText('Accessed via', printCanvas.width - 160, 30)

        const logoWidth = 40
        const logoHeigth = logoWidth / 0.3
        const watermark2 = await loadImageAsync(BMLogo)
        context.drawImage(watermark2, printCanvas.width - 200, 40, logoHeigth, logoWidth)

        const toggles = Array.from(document.getElementsByClassName('toggle'))
        toggles.forEach((el) => el.classList.add('text-black'))

        if (legend) {
          const legendDataURL = await domtoimage.toPng(legend)
          const legendImage = await loadImageAsync(legendDataURL)
          context.drawImage(legendImage, printCanvas.width - 250, 99)
          legend.style.whiteSpace = 'normal'
        } else if (fixedLabels) {
          fixedLabels.style.color = 'black'
          const labelsDataURL = await domtoimage.toPng(fixedLabels)
          const labelsImage = await loadImageAsync(labelsDataURL)
          context.drawImage(labelsImage, 0, 99)
        } else if (pricesToggles) {
          pricesToggles.style.color = 'black'
          const pricesTogglesDataURL = await domtoimage.toPng(pricesToggles)
          const pricesTogglesImage = await loadImageAsync(pricesTogglesDataURL)
          context.drawImage(
            pricesTogglesImage,
            50,
            printCanvas.height - (pricesToggles.offsetHeight + 50),
          )
        }

        if (statusFilters) {
          statusFilters.style.color = 'black'
          const labelsDataURL = await domtoimage.toPng(statusFilters)
          const labelsImage = await loadImageAsync(labelsDataURL)
          context.drawImage(labelsImage, canvas.width + 50, 120)
          statusFilters.style.color = 'white'
        }

        handleSeveralCharts(ref, context)

        context.globalCompositeOperation = 'destination-over'
        context.fillStyle = '#fff'
        context.fillRect(0, 0, printCanvas.width, printCanvas.height)

        const image = printCanvas.toDataURL('image/png')
        if (!image) {
          throw new Error('image creation failed')
        }

        const link = document.createElement('a')

        link.download = formattedName
        link.href = image
        document.body.appendChild(link)
        link.click()
        toggles.forEach((el) => el.classList.remove('text-black'))
        setScaleTextColor('#ffffff')

        if (fixedLabels) fixedLabels.style.color = 'white'
      }, 1000)

      toggleTooltipAndDropdown(1, chartId)
    } catch (error) {
      setScaleTextColor('#ffffff')
      app.$toastErrors(error)
    }
  }

  const downloadCanvasAsImage = async (elementId: string, fileName: string) => {
    try {
      const labelsDataURL = await domtoimage.toPng(document.getElementById(elementId))

      const link = document.createElement('a')
      link.download = fileName
      link.href = labelsDataURL
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error('image creation failed')
    }
  }

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  const image = new Image()
  image.src = WaterMarkLogo
  image.crossOrigin = 'anonymous'

  const onDownloadChartImageV2 = async ({ id, elementId, formattedName }) => {
    setScaleFontSizeY(22)
    setScaleFontSizeX(32)
    setScaleTitleFontSize(23)
    setScaleTextColor('#000000')
    setSelectedChartIdDownloadToImage(id)
    await delay(500)

    try {
      await downloadCanvasAsImage(elementId, formattedName)
      setScaleTextColor('#ffffff')
      setScaleFontSizeY()
      setScaleFontSizeX()
      setScaleTitleFontSize()
    } catch (error) {
      setScaleTextColor('#ffffff')
      setScaleFontSizeY()
      setScaleFontSizeX()
      setScaleTitleFontSize()
      app.$toastErrors(error)
    }
  }

  return { onDownloadChartImage, onDownloadChartImageV2 }
}

export default useDownloadChartImage
