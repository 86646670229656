<template>
  <div :class="{ 'h-full': !isFullScreenMode }">
    <div
      v-if="isFullScreenMode"
      class="bg-p-blue-900 fixed inset-0 z-50 h-full w-full overflow-y-auto bg-opacity-80"
      @click="expandContainer"
    />
    <div
      :class="{ 'fixed-container': isFullScreenMode }"
      :data-test="'container-' + cardTestAttribute"
      class="relative hidden h-full text-white sm:block"
    >
      <div
        v-if="isDownloadingImage"
        class="absolute top-0 left-0 inset-0 z-50 h-full loadingContainer"
      >
        <Loader size="large" class="absolute inset-[calc(50%-0.625rem)]" />
      </div>
      <Card :data-test="cardTestAttribute" :without-shadow="withoutShadow" class="rounded-xl">
        <div class="flex h-full flex-col pr-6 pt-6">
          <div v-if="!loading && !error" class="flex items-center justify-between mb-6">
            <h1
              :data-testid="`title-${formattedName}`"
              class="pl-8 text-2xl font-semibold leading-8"
            >
              <TooltipWeighted
                v-if="hasWeightProperty"
                :data-testid="cardTestAttribute"
                :is-weighted="isWeight"
                :text-content="title"
              />
              <span v-else id="id-title-standardized">{{ title }}</span>
            </h1>
            <div class="relative flex items-center gap-x-4">
              <slot name="additional-controls" />
              <ChartDataGrouping
                v-if="withDataGrouping"
                :selected-grouping="selectedGrouping"
                @change-grouping="$emit('changeGrouping', $event)"
              />
              <div v-if="startYear && endYear">
                <ChartRangeFilter
                  :default-end-year="defaultEndYear"
                  :default-start-year="defaultStartYear"
                  :end-year="endYear"
                  :start-year="startYear"
                  @change-start-year="$emit('changeStartYear', $event)"
                  @change-end-year="$emit('changeEndYear', $event)"
                />
              </div>
              <slot name="beforeIcons" />
              <div class="flex gap-1">
                <button
                  v-if="withExpandedButton"
                  id="expanded"
                  :data-test="`expanded-${dataTestAttribute}`"
                  :title="isFullScreenMode ? 'Minimize' : 'Maximize'"
                  class="expand-button text-p-yellow-500 flex h-6 w-6 items-center justify-center rounded-full hover:text-gray-600 focus:text-gray-600 focus:outline-none"
                  @click="expandContainer"
                >
                  <ArrowMinimize v-if="isFullScreenMode" class="h-6 w-6" />
                  <ArrowExpand v-else class="h-6 w-6" />
                </button>
                <div v-if="userHasDownloadPermission">
                  <DownloadOptions :data-test="dataTestAttribute" :options="downloadOptions" />
                </div>
                <template v-if="isRemovable">
                  <button class="flex items-center justify-center" @click="$emit('removeChart')">
                    <CloseIcon class="w-3.5 h-3.5 text-p-yellow-500" />
                  </button>
                </template>
              </div>
              <slot name="afterIcons" />
            </div>
          </div>
          <div class="flex justify-center">
            <slot name="extraIcons"></slot>
          </div>
          <div v-if="!loading && !error" :id="wrapperId" :class="defaultHeight" class="pl-2.5">
            <div :class="{ 'h-full': isPricesChart }" class="flex">
              <div
                :ref="chartId"
                :class="[hasToggles ? 'pr-5' : 'w-full', defaultHeight]"
                :style="`width: calc(100% - ${hasToggles ? widthContainerChart : 0}px)`"
              >
                <div v-if="isDownloadingImage && downloadVersion === 'v2'">
                  <slot name="downloadTemplate"></slot>
                </div>
                <slot v-else />
              </div>
              <div
                v-if="hasToggles"
                id="container-toggles"
                :class="[isFullScreenMode ? 'h-full-screen' : 'h-[385px]', toggleWidth]"
                class="flex flex-col justify-center pr-2"
              >
                <slot name="containerToggles"></slot>
              </div>
            </div>
          </div>
          <div v-else-if="!loading && !!error">
            <LoadDataError />
          </div>
          <div v-else class="text-white">Loading...</div>
          <span
            v-if="disclaimer"
            class="font-lato text-xxs pl-8 font-normal uppercase leading-3 text-white"
            >{{ disclaimer }}</span
          >
          <div class="py-5 pl-8">
            <HighlightQuarter
              :hidden-weight="!hasWeightProperty"
              :is-weight="isWeight"
              :source-data="sourceData"
              :test-id="'footer-' + cardTestAttribute"
            />
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ArrowExpand from '~/assets/icons/arrow-expand.svg'
import ArrowMinimize from '~/assets/icons/arrow-minimize.svg'
import CloseIcon from '~/assets/icons/close.svg'
import type { DownloadOption } from '~/components/reports/DownloadOptions/definitions'
import ChartRangeFilter from '~/components/ui/ChartRangeFilter.vue'
import { useGeneralStore } from '~/stores/general'
import { EventBus } from '~/utils/eventBus'

const generalStore = useGeneralStore()
const { changeFullScreenMode } = generalStore

const props = withDefaults(
  defineProps<{
    csvPayload: any
    loading: boolean
    orderOfHeaders?: any[]
    cardTestAttribute?: string
    title?: string
    name?: string
    view?: string
    chartId?: string
    wrapperId?: string
    error?: typeof Error
    hasToggles?: boolean
    withLimitedHeight?: boolean
    withExpandedButton?: boolean
    withoutShadow?: boolean
    hasCustomTooltip?: boolean
    downloadImageMultiple?: boolean
    toggleSize?: string
    customDataTestProp?: string
    isPricesChart?: boolean
    hasWeightProperty?: boolean
    isWeight?: boolean
    hoverOutElement?: string
    sourceData?: {
      quarter: number
      year: string
    }
    withDataGrouping?: boolean
    selectedGrouping?: string
    startYear?: number
    endYear?: number
    defaultStartYear?: number
    defaultEndYear?: number
    isRemovable?: boolean
    disclaimer?: string
    isVisibleDownloadFile?: boolean
    downloadVersion?: 'v1' | 'v2'
    downloadTemplateId?: string
  }>(),
  {
    orderOfHeaders: () => [],
    cardTestAttribute: '',
    title: '',
    name: '',
    view: '',
    chartId: '',
    wrapperId: '',
    error: null,
    hasToggles: true,
    withLimitedHeight: true,
    withExpandedButton: true,
    withoutShadow: false,
    hasCustomTooltip: false,
    downloadImageMultiple: false,
    toggleSize: 'large',
    customDataTestProp: '',
    isPricesChart: false,
    hasWeightProperty: false,
    isWeight: false,
    hoverOutElement: '',
    sourceData: null,
    isRemovable: false,
    isVisibleDownloadFile: true,
    downloadVersion: 'v1',
  },
)
const emit = defineEmits([
  'beforeDownloadChartImage',
  'afterDownloadChartImage',
  'expanded',
  'changeStartYear',
  'changeEndYear',
  'removeChart',
  'changeGrouping',
])
const isFullScreenMode = ref(false)
const addEvent = ref(false)
const element = ref()
const toggleSizes = ref({
  large: 195,
  medium: 129,
  small: 96,
})

const route = useRoute()
const slots = useSlots()
const app: any = useNuxtApp()
const vueInstance: any = getCurrentInstance()

const formattedName = computed(() => {
  const product = route.path.split('/')[1].replace('-', '_')
  return getFileNameByProduct({
    name: props.name,
    view: props.view,
    customDataTestProp: props.customDataTestProp,
    chartId: props.chartId,
    product,
  })
})

const formattedTitle = computed(() => {
  const product = route.path.split('/')[1]
  return getTitleByProduct({ name: props.name, view: props.view, chartId: props.chartId, product })
})

const { onDownloadChartImageV2, onDownloadChartImage } = useDownloadChartImage()

const dataTestAttribute = computed(() => {
  return `download-${formattedName.value}`
})
const defaultHeight = computed(() => {
  if (isFullScreenMode.value) {
    return slots.extraIcons ? 'h-full-screen-with-tabs' : 'h-full-screen'
  }
  if (!props.withLimitedHeight) return 'h-auto'
  if (props.isPricesChart) return 'h-full'

  return 'h-auto lg:h-min-[385px]'
})

const toggleWidth = computed(() => {
  if (props.toggleSize === 'small') return 'w-[96px]'
  if (props.toggleSize === 'medium') return 'w-[129px]'
  return 'w-[195px]'
})

const widthContainerChart = computed(() => {
  const size = toggleSizes.value[props.toggleSize] || toggleSizes.value.large
  return size + (props.toggleSize === 'small' ? 20 : 0)
})

const userHasDownloadPermission = computed(() => {
  return app.$authorization.hasDownloadPermission()
})

const isDownloadingImage = ref(false)

const downloadChartImage = () => {
  emit('beforeDownloadChartImage')

  isDownloadingImage.value = true
  if (props.downloadVersion === 'v1') {
    onDownloadChartImage({
      chartId: props.chartId,
      formattedName: formattedName.value,
      formattedTitle: formattedTitle.value,
    })
    isDownloadingImage.value = false
  } else {
    onDownloadChartImageV2({
      id: props.chartId,
      elementId: props.downloadTemplateId,
      formattedName: formattedName.value,
    }).finally(() => {
      isDownloadingImage.value = false
    })
  }

  emit('afterDownloadChartImage')
}

const downloadChartCSV = () => {
  const csvPayload = props.csvPayload
  const orderOfHeaders = props.orderOfHeaders
  const fileName = formattedName.value
  downloadXLSXFromPayload({ fileName, csvPayload, orderOfHeaders })
}

const expandContainer = () => {
  isFullScreenMode.value = !isFullScreenMode.value
  emit('expanded', isFullScreenMode.value)
  changeFullScreenMode(isFullScreenMode.value)
  const chart = document.getElementById(props.chartId)
  if (chart) {
    chart.style.height = isFullScreenMode.value ? '70vh' : '385px'
  }
  window.addEventListener('keyup', listenEscape)
}

const listenEscape = (event) => {
  if (isFullScreenMode.value && event.key === 'Escape') expandContainer()
}

const mouseEnterChart = () => {
  EventBus.emit('mouseonchart', true)
}
const mouseLeaveChart = () => {
  EventBus.emit('mouseonchart', false)
}

onUpdated(() => {
  element.value = document.getElementById(`container-chart-${props.chartId}`)
  if (!addEvent.value && props.hasCustomTooltip && element.value) {
    element.value.addEventListener('mouseenter', mouseEnterChart)
    element.value.addEventListener('mouseleave', mouseLeaveChart)
    addEvent.value = true
  }
})

const clearMouseListeners = (el) => {
  el.removeEventListener('mouseenter', mouseEnterChart)
  el.removeEventListener('mouseleave', mouseLeaveChart)
}

const downloadOptions = computed<DownloadOption[]>(() => {
  const options: DownloadOption[] = [
    {
      id: `img-${dataTestAttribute.value}`,
      label: 'Download Image',
      icon: 'download-image',
      action: downloadChartImage,
    },
  ]

  if (props.isVisibleDownloadFile) {
    options.unshift({
      id: `csv-${dataTestAttribute.value}`,
      label: 'Download XLSX',
      icon: 'xlsx',
      action: downloadChartCSV,
    })
  }
  return options
})

onBeforeUnmount(() => {
  window.removeEventListener('keyup', listenEscape)
  if (addEvent.value && element.value) {
    clearMouseListeners(element.value)
  }
})

watch(
  () => props.hasCustomTooltip,
  (value) => {
    nextTick(() => {
      if (!value) return
      let el = vueInstance.refs[props.chartId]

      if (props.hoverOutElement) {
        el = vueInstance.refs[props.chartId].querySelector(props.hoverOutElement)
      }

      clearMouseListeners(el)
      el?.addEventListener('mouseenter', mouseEnterChart)
      el?.addEventListener('mouseleave', mouseLeaveChart)
    })
  },
  { immediate: true },
)
</script>

<style scoped>
.fixed-container {
  height: fit-content;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 250ms;
  width: 90%;
  z-index: 90;

  @apply border-p-yellow-50 rounded-lg border-4;
}

@media (height <= 700px) {
  .expand-button {
    display: none;
  }
}

.loadingContainer {
  background: linear-gradient(0deg, rgb(255 255 255 / 5%), rgb(255 255 255 / 5%)),
    theme('colors.p-blue.50');
}
</style>
