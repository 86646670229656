<template>
  <div class="flex flex-col">
    <span class="text-xs font-semibold">Data Grouping</span>
    <div class="flex gap-2">
      <CommonFormRadioButton
        v-for="item of groupings"
        :id="item.id + item.key"
        :key="item.key"
        :checked="item.checked"
        :label="item.label"
        :name="item.name"
        size="xs"
        variant="secondary"
        @change="changeGrouping(item.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    selectedGrouping?: string
  }>(),
  {
    selectedGrouping: 'yearly',
  },
)

const emit = defineEmits<{
  changeGrouping: [value: string]
}>()

const groupings = computed(() => [
  {
    key: crypto.randomUUID(),
    id: 'yearly',
    checked: props.selectedGrouping === 'yearly',
    label: 'Yearly',
    name: 'data-grouping',
  },
  {
    key: crypto.randomUUID(),
    id: 'monthly',
    checked: props.selectedGrouping === 'monthly',
    label: 'Monthly',
    name: 'data-grouping',
  },
])

const changeGrouping = (id: string) => {
  emit('changeGrouping', id)
}
</script>
