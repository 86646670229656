<template>
  <div class="flex flex-row items-center" :data-testid="`container-year-picker-${dataTestId}`">
    <div class="mr-1">
      <CalendarIcon class="w-4 h-4 fill-current text-p-yellow-500" data-testid="icon-year-picker" />
    </div>
    <div class="relative">
      <input
        :id="`${id}-${uid}`"
        ref="input"
        :value="value"
        type="number"
        autocomplete="off"
        data-testid="input-year-picker"
        class="border rounded cursor-pointer outline-none input-year-picker max-w-[112px] h-8 px-8 py-2 text-center text-white/90"
        :class="{
          'border-p-yellow-200 focus:border-p-yellow-200 ': highlightBorder,
          'border-white/40 focus:border-border-white/40 ': !highlightBorder,
        }"
        @focus="onFocusInput"
        @keyup.enter="onEnter"
        @input="inputValue"
        @click="openDropdown"
      />
      <transition name="fade">
        <div
          v-if="isOpen && filteredYears.length > 0"
          v-closable="{
            exclude: [`${id}-${uid}`, ...excludeElements],
            handler: 'closeDropdown',
          }"
          data-testid="dropdown-container-year-picker"
          class="absolute left-0 z-40 py-1 mt-1 w-full max-h-[99px] rounded dropdown-container overflow-auto"
        >
          <ul class="w-full flex flex-col items-center">
            <li
              v-for="year in filteredYears"
              :key="year"
              class="text-sm font-normal rounded-sm year-item w-fit cursor-pointer py-0.5 px-1"
              :class="[year === value ? 'text-p-yellow-600' : 'text-white/60 hover:text-white/90']"
              data-testid="item-year-year-picker"
              @click="onSelectYear(year)"
            >
              {{ year }}
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import CalendarIcon from '~/assets/icons/calendar.svg'

const props = withDefaults(
  defineProps<{
    dataTestId: string
    value: number
    id: string
    excludeElements?: string[]
    years: number[]
    highlightBorder?: boolean
  }>(),
  {
    excludeElements: () => [],
    highlightBorder: false,
  },
)

const uid = getCurrentInstance()?.uid
const isOpen = ref(false)
const input = ref()

const emit = defineEmits(['changeValue', 'invalidDate'])

const filteredYears = ref([])

watch(
  () => props.years,
  () => {
    filteredYears.value = props.years
  },
  {
    immediate: true,
  },
)

const onFocusInput = () => {
  input.value.select()
}

const inputValue = (event: Event) => {
  const target = event.target as HTMLInputElement
  const value = target.value
  filteredYears.value = props.years.filter((year) => year.toString().startsWith(value))

  if (filteredYears.value.length === 0) {
    emit('invalidDate')
    closeDropdown()
    input.value.value = props.value
    filteredYears.value = props.years
  }
}

const openDropdown = () => {
  isOpen.value = true
}

const closeDropdown = () => {
  isOpen.value = false
}

const onEnter = (event: Event) => {
  const target = event.target as HTMLInputElement
  const isValidYear = props.years.includes(parseInt(target.value))

  if (isValidYear) {
    emit('changeValue', parseInt(target.value))
    closeDropdown()
  } else {
    emit('invalidDate')
    closeDropdown()
  }
}

const onSelectYear = (year: number) => {
  emit('changeValue', year)
  closeDropdown()
}

const focusInput = () => {
  input.value.focus()
  openDropdown()
}

defineExpose({
  closeDropdown,
  focusInput,
})
</script>

<style scoped lang="css">
.input-year-picker {
  background: var(
    --elevation-overlay-01-dp,
    linear-gradient(0deg, rgba(255 255 255 / 5%) 0%, rgba(255 255 255 / 5%) 100%),
    #071830
  );
}

.input-year-picker::-webkit-outer-spin-button,
.input-year-picker::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.input-year-picker[type='number'] {
  appearance: textfield;
}

.dropdown-container {
  background: var(
    --elevation-overlay-01-dp,
    linear-gradient(0deg, rgba(255 255 255 / 5%) 0%, rgba(255 255 255 / 5%) 100%),
    #071830
  );
  box-shadow: 0 4px 4px 0 rgba(0 0 0 / 25%);
}

.dropdown-container::-webkit-scrollbar {
  @apply w-1;
}

.dropdown-container::-webkit-scrollbar-track {
  @apply bg-p-blue-50 rounded-xl;
}

.dropdown-container::-webkit-scrollbar-thumb {
  @apply bg-p-yellow-50 w-1 rounded-xl;
}

.year-item:hover {
  background: var(
    --elevation-overlay-12-dp,
    linear-gradient(0deg, rgba(255 255 255 / 14%) 0%, rgba(255 255 255 / 14%) 100%),
    #071830
  );
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
</style>
