<template>
  <transition name="fade">
    <div
      v-if="showInvalidDate"
      v-closable="{
        handler: 'closeInvalidDate',
      }"
      class="text-white container-invalid-date bg-s-red-100 absolute top-full mt-2 rounded-xl p-3 flex flex-col z-40 w-[276px]"
    >
      <span class="text-xs font-bold text-p-yellow-500">Issue: Date range</span>
      <span class="text-xs font-normal text-white/90">
        The available date range is from
        <span class="font-bold text-p-yellow-500">{{ startYear }}</span> to
        <span class="font-bold text-p-yellow-500">{{ endYear }}</span
        >. We have updated the date input to the {{ lastUpdatedValue }} available value.
      </span>
    </div>
  </transition>
</template>

<script setup lang="ts">
const emit = defineEmits(['closeInvalidDate'])

const props = defineProps<{
  startYear: number
  endYear: number
  lastUpdatedValue: number
  showInvalidDate: boolean
}>()

const timeOut = ref()

const closeInvalidDate = () => {
  emit('closeInvalidDate')
  if (timeOut.value) {
    clearTimeout(timeOut.value)
    timeOut.value = null
  }
}

const closeInvalidDateWithDelay = () => {
  if (timeOut.value) {
    clearTimeout(timeOut.value)
    timeOut.value = null
  }
  timeOut.value = setTimeout(() => {
    closeInvalidDate()
  }, 3000)
}

defineExpose({
  closeInvalidDate,
})

watch(
  () => props.showInvalidDate,
  (value) => {
    if (value) {
      closeInvalidDateWithDelay()
    } else {
      closeInvalidDate()
    }
  },
)
</script>

<style scoped>
.container-invalid-date {
  background-color: #020c18e5;
  fill: rgba(2 12 24 / 80%);
  filter: drop-shadow(0 4px 4px rgba(0 0 0 / 25%));
  backdrop-filter: blur(2px);
}
</style>
